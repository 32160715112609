.icon {
  width: 100%;
}

.enabled {
  background-color: #2A2E43;
}

.disabled {
  background-color: rgba(0, 0, 0, 0.26);
}

.appointment-icon {
  -webkit-mask: url('/images/pregledi.svg') no-repeat center;
  mask: url('/images/pregledi.svg') no-repeat center;
}

.test-icon {
  -webkit-mask: url('/images/tests.svg') no-repeat center;
  mask: url('/images/tests.svg') no-repeat center;
}

.reimbursement-icon {
  -webkit-mask: url('/images/razhodi.svg') no-repeat center;
  mask: url('/images/razhodi.svg') no-repeat center;
}

.extra-care-icon {
  -webkit-mask: url('/images/extra-care.svg') no-repeat center;
  mask: url('/images/extra-care.svg') no-repeat center;
}

.map-icon {
  -webkit-mask: url('/images/karta.svg') no-repeat center;
  mask: url('/images/karta.svg') no-repeat center;
}

.coverage-icon {
  -webkit-mask: url('/images/pokritia.svg') no-repeat center;
  mask: url('/images/pokritia.svg') no-repeat center;
}

.container {
  width: auto;
  min-width:360px;
  max-width:500px;
  padding: 2em;
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
}

@media (max-width: 650px) {
  .container {
    overflow: scroll;
    max-width: 90%;
    min-width: 300px;
    max-height: 90vh;
  }
}
.back-btn {
  display: inline-block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  width: fit-content;
  padding-left: 0.1em;
}

.icon {
  width: 0.9rem;
  margin-right: 0.1em;
}

span.span-style {
  text-transform: uppercase;
  transition: margin .3s ease-in-out;
}

.back-btn:hover span.span-style {
  margin-left: 0.5em;
}

.logout-button {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .back-btn {
    margin-bottom: 1rem;
  }

  .logout-button {
    margin-bottom: 0.8rem;
  }
}
.container {
  padding: 2em;
  background-image: url("/images/user-card.png");
  background-size: 3rem;
  background-position: 1rem 1rem;
  height: 100%;
  width: 100%;
  color: #FFF;
  border-radius: 1em;
  border: 2px solid #e1e1e1;
  font-size: 0.8rem;
}

.container p {
  margin-bottom: 0;
}

.first-row img {
  max-width: 9rem;
  width: 100%;
}

.about {
  line-height: 1.35;
}

.second-row,
.third-row {
  margin: 1rem 0;
}

.second-row {
  max-width: 20rem;
}

.second-row > .second-row-item {
  display: flex;
  align-items: center;
}

.second-row > .second-row-item > .grid-item-first-column {
  margin-right: 0.5em;
}

.second-row> .second-row-item:not(:last-child) {
  margin-bottom: 0.5em;
}

.second-row .white-field {
  background: #FFFFFF;
  color: #575e62;
  border-radius: 1em;
  padding: 0 0.5em;
  flex-grow: 1;
}

.line {
  width: 100%;
  border: 0.1rem solid #FFFFFF;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  text-align: center;
  line-height: 1.3rem;
}

table th,
table td {
  border: 1px solid #FFFFFF;
}

table th:first-child,
table td:first-child {
  text-align: left;
}

.dot {
  height: 0.4rem;
  width: 0.4rem;
  background: #FFFFFF;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.last-row p:not(:last-child) {
  margin-bottom: 1rem;
}
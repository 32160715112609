.container {
  font-size: 1rem;
  line-height: 1.5;
  padding: 2em 0;
  --bg: #d42f42;
}

.list ul li {
  list-style-type: none;
}

.list ul li::before {
  content: '- ';
}

.heading {
  font-size: 2rem;
  max-width: 35ch;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.timeline-wrapper {
  --padding: 4em;
  --line-position: calc(var(--padding) / 2);

  padding-left: var(--padding);
  margin: 3em 0 2em 0;
  position: relative;
}

.timeline {
  position: absolute;
  top: 0;
  left: var(--line-position);
  transform: translateX(-50%);
  width: 3px;
  height: 100%;
  border-radius: 1.5px;
  background-color: var(--bg);
}

.timeline-content-block {
  padding-left: 2em;
  margin-bottom: 4em;
  position: relative;
}

.timeline-content-block:last-of-type {
  margin-bottom: 0;
}

.timeline-content-block::before {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  background-color: var(--bg);
  top: 0;
  left: calc(var(--line-position) * -1);
  transform: translateX(-50%);
}

.timeline-year {
  position: absolute;
  background-color: #fff;
  border-radius: 3px;
  padding: 2px 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  top: 0;
  left: calc(var(--line-position) * -1);
  transform: translateY(100%) translateX(-50%);
}

.logo {
  max-width: 150px;
  margin-bottom: 1.5em;
}

.red {
  color: var(--bg);
  cursor: pointer;
}

.mobile-app-box {
  margin: 3em 0;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.mobile-app-header {
  display: flex;
  align-items: center;
}

.mobile-app-header > h2 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.6rem;
}

.mobile-app-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2em;
}

.mobile-app-description {
  margin: 1em 0;
  padding: 0;
}

.mobile-app-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2em;
}

.mobile-app-icon {
  margin-right: 2em;
}

@media screen and (max-width: 769px) {
  .heading {
    font-size: 1.5rem;
    line-height: 1.3;
  }
  .timeline-year {
    font-size: 0.9rem;
  }

  .mobile-app-icons {
    flex-direction: column;
    align-items: start;
  }

  .mobile-app-icon {
    width: 100%;
    margin: 0 0 1em 0;
  }

  .mobile-app-header {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .mobile-app-header > h2 {
    text-align: center;
    margin-bottom: 0.5em;
  }
}

.container {
  margin-bottom: 2em;
}

.container>div:first-child {
  padding-left: 0;
  margin-bottom: 2em;
}

.declarations-label {
  font-size: 0.9em;
  margin-bottom: 1em;
  color: rgba(0, 0, 0, 0.54);
}

.declarations-list {
  padding-left: 0;
  list-style: inside;
}

.declarations li {
  margin-bottom: 0.5em;
}

.tab-button {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  margin-right: 2rem;
  outline: 0;
  background: none !important;
}

.active {
  font-weight: 700;
  border-bottom: 2px solid #D42F42;
}

.form-container {
  margin-top: 1.4em;
}

.divider-container {
  margin: 1.4em 0;
}

.facebook-container > button {
  margin-bottom: 1.4em;
}
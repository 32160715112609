.container {
  padding: 1.5em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  border: 2px solid #e1e1e1;
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  align-items: flex-start;
}

.container:not(:last-child) {
  margin-bottom: 2em
}

.container p {
  margin-bottom: 0;
}

.container .subtitle {
  color: #999;
}

.container button,
.container > span {
  margin-left: auto;
}

@media screen and (min-width: 550px) {
  .direction-row {
    flex-direction: row;
  }
}

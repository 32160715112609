.header {
  text-transform: uppercase;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  border: 2px solid #e1e1e1;
  border-radius: 3px;
}

.items .first-column {
  padding: 4em;
}

.items .first-column h3 {
  margin: 0 0 1em 0;
}

.first-column .form {
    margin-bottom: 2em;
}

.first-column .form label {
  margin: 0 0 0.5em 0;
}

.first-column .form label>span:first-child {
  padding: 0;
  margin-right: 0.3em;
}
.container {
  width: 100%;
  border: 2px solid #000;
  border-radius: 0.5em;
  padding: 1em;
}

.container:not(:last-child) {
  margin-bottom: 2em;
}

.title-icons-container {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 5fr 1fr;
  align-items: center;
}

.title {
  font-size: 1rem;
  line-height: 1.1;
}

.button input {
  display: none;
}

.button label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

.button span {
  padding: 1px;
  color: #2a2e43;
}

.button input:disabled + label,
.disabled {
  color: #ccc;
}

.files {
  margin: 2.5em 0 0 0;
  padding: 0;
  list-style: none outside;
}

.file {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.file .close {
  margin-left: 0.4em;
  margin-right: 0.6em;
  color: #d42f42;
  cursor: pointer;
}

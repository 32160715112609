@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/* autoprefixer grid: autoplace */
/* @import "https://cdn.jsdelivr.net/gh/gopchi/b-assist-cdn@latest/materialize.css"; */
/* @import "https://cdn.jsdelivr.net/gh/gopchi/b-assist-cdn@latest/custom.css"; */

.w-webflow-badge {
  display: none !important;
}

.cm-hovered-zoom {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.cm-hover-block:hover > .cm-hovered-zoom {
  opacity: 1;
}

/* Prefix cm input field with icon */

.input-field .prefix ~ input,
.input-field .prefix ~ textarea,
.input-field .prefix ~ label,
.input-field .prefix ~ .validate ~ label,
.input-field .prefix ~ .helper-text,
.input-field .prefix ~ .autocomplete-content {
  margin-left: 0;
  padding-left: 2.5rem;
  width: calc(100% - 2.5rem);
}

/* Suffix is really custom class the we added in order to have the icon at the end */
/* It copies the same principle of the prefix but with different position */

.input-field .suffix {
  position: absolute;
  width: auto;
  right: 0;
  font-size: 2rem;
  transition: color 0.2s;
  top: 0.5rem;
}
.input-field .suffix ~ input,
.input-field .suffix ~ textarea,
.input-field .suffix ~ label,
.input-field .suffix ~ .validate ~ label,
.input-field .suffix ~ .helper-text,
.input-field .suffix ~ .autocomplete-content {
  padding-right: 2.5rem;
  width: calc(100% - 2.5rem);
}

/* Disable datepicker Cancel button */
.datepicker-cancel {
  display: none;
}

[type='checkbox'] + span:not(.lever) {
  padding-left: 35px;
}

.MuiFormControl-root {
  width: 100%;
}

.date-pickers {
  padding-top: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
}

.btn-holder {
  margin-top: 0;
  padding: 20px 0 0 0;
}

.flex-space-between {
  justify-content: space-between;
}

.cm-heading-onboarding {
  font-size: 30px;
}

.text-span {
  border-bottom: 2px solid #d33244;
  line-height: 1.4;
  padding-bottom: 0;
}

.card-process-travel-ins {
  padding-bottom: 20px;
  margin-top: 20px;
}

.travel-form-heading {
  font-size: 120%;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.select-wrapper-group {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2em;
  width: 100%;
}

.select-wrapper {
  width: 100%;
}

.cm-group-select {
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.grid-gap-2 {
  grid-row-gap: 2em;
}

.cm-buy-paragraph {
  grid-column: span 2;
}

.card-content-travel-ins {
  padding: 15px 20px 10px 20px;
}

.cm-button {
  padding: 15px 50px;
  outline: 0;
  transition: 0.2s ease-in;
  font-weight: 500;
  font-size: 0.875rem;
  border: 2px solid #d33244;
}

.cm-button:hover {
  opacity: 0.9;
}

.cm-button:disabled {
  color: #fff;
  opacity: 0.5;
}

.cm-button.outlined:disabled {
  color: #333;
  background-color: rgba(0, 0, 0, 0.2);
}

.cm-checkbox-position-span-grid {
  grid-column: span 2;
  justify-self: end;
  margin-right: 0 !important;
  margin-top: -15px !important;
}

.cm-material-button {
  padding: 13px 20px !important;
  border-radius: 0 !important;
}

.cm-material-button:hover {
  background-color: transparent !important;
}

.coinsured-row-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
}

.cm-review-group {
  display: grid;
  justify-content: space-around;
  align-content: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.cm-review-group.p-travel-5-cm-grid {
  grid-template-columns: 1fr 1fr 0.5fr 1fr;
}

.normal-font-weight {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

/* Notifications */

.n-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30%;
  height: auto;
}

.n-default {
  width: 100%;
  overflow: hidden;
  position: relative;
  right: 2em;
  display: grid;
  grid-template-columns: minmax(10px, 30px) 1fr;
  align-items: center;
  grid-gap: 0.5rem;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s ease-in-out, right 0.5s ease-in-out;
  z-index: 9999999;
  margin-bottom: 1rem;
}

.n-add {
  -webkit-animation: ntf-in 500ms ease forwards;
          animation: ntf-in 500ms ease forwards;
}

/* Navigation bar */

.container.cm-nav-logged-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  --webkit-align-items: center;
}

.nav-bar-logged-in {
  display: flex;
  padding-left: 30px;
  justify-content: flex-end;
  align-items: center;
  line-height: 1;
}

.nav-border {
  border-bottom: 1px solid #cfcfcf;
}

@-webkit-keyframes ntf-in {
  from {
    right: -100%;
  }
  to {
    right: 2em;
  }
}

@keyframes ntf-in {
  from {
    right: -100%;
  }
  to {
    right: 2em;
  }
}

@-webkit-keyframes ntf-leave {
  to {
    right: -100%;
    opacity: 0;
  }
}

@keyframes ntf-leave {
  to {
    right: -100%;
    opacity: 0;
  }
}

a {
  text-decoration: none;
}

.cm-order-summary {
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 15px 2em;
  grid-row-gap: 0;
  row-gap: 0;
}

.cm-order-summary .travel-form-heading {
  margin-bottom: 2em;
  grid-column: span 2;
}

.order-summary-justified-end-item {
  justify-self: end;
}

.total-heading,
.total-number {
  font-weight: 800;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 190%;
  margin: 0;
}

.payment-methods {
  grid-column: span 2;
}

.payment-methods .pay-now-btn {
  width: 100%;
  margin-top: 1.5em;
}

.FB_CM_DISCOUNT {
  width: 100%;
  margin: 1em 0 !important;
  padding: 1.5em !important;
}

/* Queries */

@media screen and (max-width: 768px) {
  .n-default {
    width: 90%;
  }

  .cm-button {
    width: 100%;
  }

  .cm-group-select,
  .select-wrapper-group {
    grid-template-columns: 1fr;
  }

  .date-pickers {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  .w-container {
    padding: 0 15px;
  }

  .btn-holder {
    flex-direction: column-reverse;
    width: 100%;
  }

  .buy-process {
    margin: 0;
  }

  .btn-holder .cm-button.outlined {
    margin-bottom: 1rem;
  }

  .grid-m-c-1 {
    grid-template-columns: 1fr !important;
    margin-top: 0;
  }

  .p-buy-insurance-info-img {
    display: none;
  }

  .cm-checkbox-position-span-grid {
    grid-column: auto;
    justify-self: start;
  }

  .cm-review-group {
    grid-template-columns: 1fr;
  }

  .cm-heading-onboarding {
    font-size: 1.5em;
    line-height: 1;
  }

  .text-span-2 {
    line-height: 2;
  }
}

.p-buy-insurance-info-grid p {
  line-height: 1.3;
}

@media screen and (max-width: 992px) {
  .p-buy-insurance-info-grid p {
    line-height: 1.3;
    font-size: 16px;
    font-weight: 400;
  }
}

.carousel__dot {
  width: 15px;
  height: 15px;
  background: #fff;
  margin: 0 5px;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
}
.carousel__dot--selected {
  background-color: #d42f42;
  border: 1px solid #fff;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.icon-styles_icon__1h4gG {
  width: 100%;
}

.icon-styles_enabled__H_rTQ {
  background-color: #2A2E43;
}

.icon-styles_disabled__1vhLn {
  background-color: rgba(0, 0, 0, 0.26);
}

.icon-styles_appointment-icon__2LDJQ {
  -webkit-mask: url('/images/pregledi.svg') no-repeat center;
  mask: url('/images/pregledi.svg') no-repeat center;
}

.icon-styles_test-icon__gJISH {
  -webkit-mask: url('/images/tests.svg') no-repeat center;
  mask: url('/images/tests.svg') no-repeat center;
}

.icon-styles_reimbursement-icon__33EY9 {
  -webkit-mask: url('/images/razhodi.svg') no-repeat center;
  mask: url('/images/razhodi.svg') no-repeat center;
}

.icon-styles_extra-care-icon__r2-9C {
  -webkit-mask: url('/images/extra-care.svg') no-repeat center;
  mask: url('/images/extra-care.svg') no-repeat center;
}

.icon-styles_map-icon__3AJTu {
  -webkit-mask: url('/images/karta.svg') no-repeat center;
  mask: url('/images/karta.svg') no-repeat center;
}

.icon-styles_coverage-icon__1zkIJ {
  -webkit-mask: url('/images/pokritia.svg') no-repeat center;
  mask: url('/images/pokritia.svg') no-repeat center;
}

.editable-card_title__3r4qR {
  margin: 0 0 2em 0;
  padding:0;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1;
}
.user-grid_container__1000O {
  margin-top: 0;
}

.user-grid_card__1koKW {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, .33);
  padding: 2em;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  height: 100%
}

@media(max-width: 1023px) {
  .user-grid_two-columns__U2Tee {
    grid-column: span 2;
  }
}

@media(max-width: 768px) {
  .user-grid_container__1000O {
    margin-top: 1.4em;
  }
}
.user-card_container__3h5r6 {
  padding: 2em;
  background-image: url("/images/user-card.png");
  background-size: 3rem;
  background-position: 1rem 1rem;
  height: 100%;
  width: 100%;
  color: #FFF;
  border-radius: 1em;
  border: 2px solid #e1e1e1;
  font-size: 0.8rem;
}

.user-card_container__3h5r6 p {
  margin-bottom: 0;
}

.user-card_first-row__39dBd img {
  max-width: 9rem;
  width: 100%;
}

.user-card_about__gqfXf {
  line-height: 1.35;
}

.user-card_second-row__KsrFx,
.user-card_third-row__JaN2_ {
  margin: 1rem 0;
}

.user-card_second-row__KsrFx {
  max-width: 20rem;
}

.user-card_second-row__KsrFx > .user-card_second-row-item__2PWey {
  display: flex;
  align-items: center;
}

.user-card_second-row__KsrFx > .user-card_second-row-item__2PWey > .user-card_grid-item-first-column__1p0i_ {
  margin-right: 0.5em;
}

.user-card_second-row__KsrFx> .user-card_second-row-item__2PWey:not(:last-child) {
  margin-bottom: 0.5em;
}

.user-card_second-row__KsrFx .user-card_white-field__3iOJA {
  background: #FFFFFF;
  color: #575e62;
  border-radius: 1em;
  padding: 0 0.5em;
  flex-grow: 1;
}

.user-card_line__1_PDn {
  width: 100%;
  border: 0.1rem solid #FFFFFF;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  text-align: center;
  line-height: 1.3rem;
}

table th,
table td {
  border: 1px solid #FFFFFF;
}

table th:first-child,
table td:first-child {
  text-align: left;
}

.user-card_dot__1w7bH {
  height: 0.4rem;
  width: 0.4rem;
  background: #FFFFFF;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.user-card_last-row__2xhtR p:not(:last-child) {
  margin-bottom: 1rem;
}
.info-card_container__pvoBJ {
  padding: 1.5em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  border: 2px solid #e1e1e1;
}

.info-card_direction-column__1x2ca {
  flex-direction: column;
}

.info-card_direction-row__zEQla {
  align-items: flex-start;
}

.info-card_container__pvoBJ:not(:last-child) {
  margin-bottom: 2em
}

.info-card_container__pvoBJ p {
  margin-bottom: 0;
}

.info-card_container__pvoBJ .info-card_subtitle__1t-ZS {
  color: #999;
}

.info-card_container__pvoBJ button,
.info-card_container__pvoBJ > span {
  margin-left: auto;
}

@media screen and (min-width: 550px) {
  .info-card_direction-row__zEQla {
    flex-direction: row;
  }
}

.button-container_button-container__1m1NM {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  .button-container_button-container__1m1NM {
    flex-direction: column;
    padding: 1em 0;
  }

  .button-container_button-container__1m1NM button {
    margin: 0.5em 0 !important;
    width: 100%;
  }
}

.button-container_custom-social-card-button-container__1ZZTP {
  padding: 0;
}

.button-container_custom-social-card-button-container__1ZZTP button {
  margin: 0 !important
}

@media (max-width: 600px) {
  .button-container_custom-social-card-button-container__1ZZTP {
    margin-top: 1em;
  }
}
.back-btn_back-btn__3Ivoa {
  display: inline-block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 0.1em;
}

.back-btn_icon__3O-aw {
  width: 0.9rem;
  margin-right: 0.1em;
}

span.back-btn_span-style__2y1FD {
  text-transform: uppercase;
  transition: margin .3s ease-in-out;
}

.back-btn_back-btn__3Ivoa:hover span.back-btn_span-style__2y1FD {
  margin-left: 0.5em;
}

.back-btn_logout-button__3t4b6 {
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .back-btn_back-btn__3Ivoa {
    margin-bottom: 1rem;
  }

  .back-btn_logout-button__3t4b6 {
    margin-bottom: 0.8rem;
  }
}
.file-upload_container__1zTLg {
  width: 100%;
  border: 2px solid #000;
  border-radius: 0.5em;
  padding: 1em;
}

.file-upload_container__1zTLg:not(:last-child) {
  margin-bottom: 2em;
}

.file-upload_title-icons-container__3NaGE {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 5fr 1fr;
  align-items: center;
}

.file-upload_title__3Y3h0 {
  font-size: 1rem;
  line-height: 1.1;
}

.file-upload_button__2JvCP input {
  display: none;
}

.file-upload_button__2JvCP label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

.file-upload_button__2JvCP span {
  padding: 1px;
  color: #2a2e43;
}

.file-upload_button__2JvCP input:disabled + label,
.file-upload_disabled__1K4y- {
  color: #ccc;
}

.file-upload_files__Wd0zq {
  margin: 2.5em 0 0 0;
  padding: 0;
  list-style: none outside;
}

.file-upload_file__168KE {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.file-upload_file__168KE .file-upload_close__2zbSE {
  margin-left: 0.4em;
  margin-right: 0.6em;
  color: #d42f42;
  cursor: pointer;
}

.phone-field_phone__1ScMM {
  margin: 1.2em 0;
  color: #818181;
}

.phone-field_phone__1ScMM > div input {
  color: rgba(0, 0, 0, 0.87);
}

.map_header__LI45Y {
  text-transform: uppercase;
}

.map_items__zpZ1Y {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  border: 2px solid #e1e1e1;
  border-radius: 3px;
}

.map_items__zpZ1Y .map_first-column__2e7Ez {
  padding: 4em;
}

.map_items__zpZ1Y .map_first-column__2e7Ez h3 {
  margin: 0 0 1em 0;
}

.map_first-column__2e7Ez .map_form__3YiOJ {
    margin-bottom: 2em;
}

.map_first-column__2e7Ez .map_form__3YiOJ label {
  margin: 0 0 0.5em 0;
}

.map_first-column__2e7Ez .map_form__3YiOJ label>span:first-child {
  padding: 0;
  margin-right: 0.3em;
}
.dialog_container__hDZBC {
  width: auto;
  min-width:360px;
  max-width:500px;
  padding: 2em;
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
}

@media (max-width: 650px) {
  .dialog_container__hDZBC {
    overflow: scroll;
    max-width: 90%;
    min-width: 300px;
    max-height: 90vh;
  }
}
.auth-tabs_tab-button__1T5js {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0;
  margin-right: 2rem;
  outline: 0;
  background: none !important;
}

.auth-tabs_active__2-s1G {
  font-weight: 700;
  border-bottom: 2px solid #D42F42;
}

.auth-tabs_form-container__26p_a {
  margin-top: 1.4em;
}

.auth-tabs_divider-container__3CcQd {
  margin: 1.4em 0;
}

.auth-tabs_facebook-container__3c-TJ > button {
  margin-bottom: 1.4em;
}
.s_footer__XqZEV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.s_selected__34LK6 {
  border-bottom: 2px solid #d42f42;
}

.s_container__3WpYM {
  background: #523f5a;
  padding: 3em 5em 2em 5em;
  color: #ffffff;
  margin-top: auto;
}

.s_icons-container__10JRv {
  display: flex;
}

.s_icons-container__10JRv svg {
  margin-right: 0.2em;
}

.s_icons-container__10JRv svg:last-child {
  margin-right: 0;
}

.s_grid__2qFjx {
  margin-bottom: 2em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3em;
}

.s_link__3X0gE {
  display: block;
  color: #fff;
  margin-bottom: 1em;
  font-size: 1rem;
  font-weight: 300;
}

.s_text__3PzsY {
  text-align: center;
  display: block;
  color: #aaa;
  margin-top: 1em !important;
}

.s_bold__Tp_el {
  font-size: 1.2em;
}

@media screen and (max-width: 768px) {
  .s_container__3WpYM {
    padding: 2em 1em;
    text-align: center;
  }

  .s_icons-container__10JRv {
    justify-content: center;
  }
}

@media screen and (max-width: 1023px) {
  .s_grid__2qFjx {
    grid-template-columns: 1fr;
  }

  .s_grid__2qFjx.s_capacitor__1jRLn {
    grid-gap: 0;
  }
}

.filled-data_container__hU9JG {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filled-data_documents-list__3PEoD,
.filled-data_services-list__3mG4O {
  margin-bottom: 2em;
  padding: 0;
}

.filled-data_documents-list__3PEoD li,
.filled-data_services-list__3mG4O li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.filled-data_services-list__3mG4O li > span:first-child {
  color: #999999;
}

.filled-data_documents-list__3PEoD .filled-data_file-group__xve1u {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.filled-data_documents-list__3PEoD .filled-data_file-group__xve1u svg {
  margin-left: 0.5em;
}

.filled-data_container__hU9JG button {
  margin-top: auto;
  align-self: flex-start;
}

.step-confirm_container__2zYU_ {
  margin-bottom: 2em;
}

.step-confirm_container__2zYU_>div:first-child {
  padding-left: 0;
  margin-bottom: 2em;
}

.step-confirm_declarations-label__1qKoS {
  font-size: 0.9em;
  margin-bottom: 1em;
  color: rgba(0, 0, 0, 0.54);
}

.step-confirm_declarations-list__2L2Lp {
  padding-left: 0;
  list-style: inside;
}

.step-confirm_declarations__DHZ-g li {
  margin-bottom: 0.5em;
}

.s_home-tagline__Skboi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.3em;
  margin: 2em 0;
  text-align: center;
}

.s_home-tagline__Skboi > .s_arrow-grid__2rHWa {
  display: grid;
  grid-template-columns: minmax(100px, 120px) 1fr;
  grid-column-gap: 1em;
}

.s_home-tagline__Skboi > .s_arrow-grid__2rHWa > img {
  padding-top: 10px;
  width: 3em;
  justify-self: end;
}

.s_home-products-grid__dZ31A {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 80vh;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  overflow: hidden;
}

.s_second-prod__2ziSq {
  text-align: right;
  align-items: flex-end;
}

.s_home-products-grid__dZ31A img {
  width: 100%;
}

.s_product-info__56uBB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 2em;
  font-family: Helvetica, sans-serif;
}

.s_product-info__56uBB > h1 {
  max-width: 70%;
  margin-bottom: 1em;
  font-size: 3.2em;
  color: #2A2E43;
}

.s_product-info__56uBB p {
  font-size: 1.2rem;
  font-weight: 300;
  max-width: 70%;
  line-height: 1.3;
  color: #767676;
  margin-bottom: 2em;
}

.s_product-info__56uBB ul {
  padding-left: 0;
}

.s_product-info__56uBB li {
  line-height: 1.5;
  list-style: none;
}

.s_product-info__56uBB .s_link__1m6Tq {
  font-size: 1.2rem;
  font-weight: 300;
  color: #D42F42
}

@media screen and (max-width: 768px) {
  .s_home-tagline__Skboi {
    margin-bottom: 0;
  }
  .s_home-products-grid__dZ31A {
    margin: 2em 0;
    grid-template-columns: 1fr;
    height: auto;
  }

  .s_home-products-grid__dZ31A img {
    grid-row: 1;
  }

  .s_product-info__56uBB {
    padding: 2em 1em;
  }

  .s_product-info__56uBB > h1 {
    word-break: keep-all;
    max-width: 100%;
    font-size: 2.4em;
    line-height: 1.1;
  }


  .s_product-info__56uBB p {
    font-size: 1rem;
    max-width: 100%;
  }

  .s_home-tagline__Skboi > .s_arrow-grid__2rHWa > p {
    grid-column: span 2;
  }

  .s_home-tagline__Skboi > .s_arrow-grid__2rHWa > img  {
    display: none;
  }
}

.s_container__Faoje {
  font-size: 1rem;
  line-height: 1.5;
  padding: 2em 0;
  --bg: #d42f42;
}

.s_list__3B1Ao ul li {
  list-style-type: none;
}

.s_list__3B1Ao ul li::before {
  content: '- ';
}

.s_heading__3BTav {
  font-size: 2rem;
  max-width: 35ch;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.s_timeline-wrapper__390rD {
  --padding: 4em;
  --line-position: calc(var(--padding) / 2);

  padding-left: var(--padding);
  margin: 3em 0 2em 0;
  position: relative;
}

.s_timeline__1adk0 {
  position: absolute;
  top: 0;
  left: var(--line-position);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 3px;
  height: 100%;
  border-radius: 1.5px;
  background-color: var(--bg);
}

.s_timeline-content-block__3J2EY {
  padding-left: 2em;
  margin-bottom: 4em;
  position: relative;
}

.s_timeline-content-block__3J2EY:last-of-type {
  margin-bottom: 0;
}

.s_timeline-content-block__3J2EY::before {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 100%;
  position: absolute;
  background-color: var(--bg);
  top: 0;
  left: calc(var(--line-position) * -1);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.s_timeline-year__ECyR- {
  position: absolute;
  background-color: #fff;
  border-radius: 3px;
  padding: 2px 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  top: 0;
  left: calc(var(--line-position) * -1);
  -webkit-transform: translateY(100%) translateX(-50%);
          transform: translateY(100%) translateX(-50%);
}

.s_logo__1QG5N {
  max-width: 150px;
  margin-bottom: 1.5em;
}

.s_red__1F-lj {
  color: var(--bg);
  cursor: pointer;
}

.s_mobile-app-box__3N5KO {
  margin: 3em 0;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
}

.s_mobile-app-header__20kJH {
  display: flex;
  align-items: center;
}

.s_mobile-app-header__20kJH > h2 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.6rem;
}

.s_mobile-app-icon__2W9Jh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2em;
}

.s_mobile-app-description__1Y2Yy {
  margin: 1em 0;
  padding: 0;
}

.s_mobile-app-icons__1W_GC {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2em;
}

.s_mobile-app-icon__2W9Jh {
  margin-right: 2em;
}

@media screen and (max-width: 769px) {
  .s_heading__3BTav {
    font-size: 1.5rem;
    line-height: 1.3;
  }
  .s_timeline-year__ECyR- {
    font-size: 0.9rem;
  }

  .s_mobile-app-icons__1W_GC {
    flex-direction: column;
    align-items: start;
  }

  .s_mobile-app-icon__2W9Jh {
    width: 100%;
    margin: 0 0 1em 0;
  }

  .s_mobile-app-header__20kJH {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .s_mobile-app-header__20kJH > h2 {
    text-align: center;
    margin-bottom: 0.5em;
  }
}


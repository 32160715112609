.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.documents-list,
.services-list {
  margin-bottom: 2em;
  padding: 0;
}

.documents-list li,
.services-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.services-list li > span:first-child {
  color: #999999;
}

.documents-list .file-group {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.documents-list .file-group svg {
  margin-left: 0.5em;
}

.container button {
  margin-top: auto;
  align-self: flex-start;
}

/* autoprefixer grid: autoplace */

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
/* @import "https://cdn.jsdelivr.net/gh/gopchi/b-assist-cdn@latest/materialize.css"; */
/* @import "https://cdn.jsdelivr.net/gh/gopchi/b-assist-cdn@latest/custom.css"; */

.w-webflow-badge {
  display: none !important;
}

.cm-hovered-zoom {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.cm-hover-block:hover > .cm-hovered-zoom {
  opacity: 1;
}

/* Prefix cm input field with icon */

.input-field .prefix ~ input,
.input-field .prefix ~ textarea,
.input-field .prefix ~ label,
.input-field .prefix ~ .validate ~ label,
.input-field .prefix ~ .helper-text,
.input-field .prefix ~ .autocomplete-content {
  margin-left: 0;
  padding-left: 2.5rem;
  width: calc(100% - 2.5rem);
}

/* Suffix is really custom class the we added in order to have the icon at the end */
/* It copies the same principle of the prefix but with different position */

.input-field .suffix {
  position: absolute;
  width: auto;
  right: 0;
  font-size: 2rem;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  top: 0.5rem;
}
.input-field .suffix ~ input,
.input-field .suffix ~ textarea,
.input-field .suffix ~ label,
.input-field .suffix ~ .validate ~ label,
.input-field .suffix ~ .helper-text,
.input-field .suffix ~ .autocomplete-content {
  padding-right: 2.5rem;
  width: calc(100% - 2.5rem);
}

/* Disable datepicker Cancel button */
.datepicker-cancel {
  display: none;
}

[type='checkbox'] + span:not(.lever) {
  padding-left: 35px;
}

.MuiFormControl-root {
  width: 100%;
}

.date-pickers {
  padding-top: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
}

.btn-holder {
  margin-top: 0;
  padding: 20px 0 0 0;
}

.flex-space-between {
  justify-content: space-between;
}

.cm-heading-onboarding {
  font-size: 30px;
}

.text-span {
  border-bottom: 2px solid #d33244;
  line-height: 1.4;
  padding-bottom: 0;
}

.card-process-travel-ins {
  padding-bottom: 20px;
  margin-top: 20px;
}

.travel-form-heading {
  font-size: 120%;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.select-wrapper-group {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2em;
  width: 100%;
}

.select-wrapper {
  width: 100%;
}

.cm-group-select {
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.grid-gap-2 {
  grid-row-gap: 2em;
}

.cm-buy-paragraph {
  grid-column: span 2;
}

.card-content-travel-ins {
  padding: 15px 20px 10px 20px;
}

.cm-button {
  padding: 15px 50px;
  outline: 0;
  transition: 0.2s ease-in;
  font-weight: 500;
  font-size: 0.875rem;
  border: 2px solid #d33244;
}

.cm-button:hover {
  opacity: 0.9;
}

.cm-button:disabled {
  color: #fff;
  opacity: 0.5;
}

.cm-button.outlined:disabled {
  color: #333;
  background-color: rgba(0, 0, 0, 0.2);
}

.cm-checkbox-position-span-grid {
  grid-column: span 2;
  justify-self: end;
  margin-right: 0 !important;
  margin-top: -15px !important;
}

.cm-material-button {
  padding: 13px 20px !important;
  border-radius: 0 !important;
}

.cm-material-button:hover {
  background-color: transparent !important;
}

.coinsured-row-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
}

.cm-review-group {
  display: -ms-grid;
  display: grid;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-align-content: end;
  -ms-flex-line-pack: end;
  align-content: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cm-review-group.p-travel-5-cm-grid {
  -ms-grid-columns: 1fr 1fr 0.5fr 1fr;
  grid-template-columns: 1fr 1fr 0.5fr 1fr;
}

.normal-font-weight {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

/* Notifications */

.n-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30%;
  height: auto;
}

.n-default {
  width: 100%;
  overflow: hidden;
  position: relative;
  right: 2em;
  display: grid;
  grid-template-columns: minmax(10px, 30px) 1fr;
  align-items: center;
  grid-gap: 0.5rem;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s ease-in-out, right 0.5s ease-in-out;
  z-index: 9999999;
  margin-bottom: 1rem;
}

.n-add {
  animation: ntf-in 500ms ease forwards;
}

/* Navigation bar */

.container.cm-nav-logged-in {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  --webkit-align-items: center;
}

.nav-bar-logged-in {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.nav-border {
  border-bottom: 1px solid #cfcfcf;
}

@keyframes ntf-in {
  from {
    right: -100%;
  }
  to {
    right: 2em;
  }
}

@keyframes ntf-leave {
  to {
    right: -100%;
    opacity: 0;
  }
}

a {
  text-decoration: none;
}

.cm-order-summary {
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 15px 2em;
  row-gap: 0;
}

.cm-order-summary .travel-form-heading {
  margin-bottom: 2em;
  grid-column: span 2;
}

.order-summary-justified-end-item {
  justify-self: end;
}

.total-heading,
.total-number {
  font-weight: 800;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 190%;
  margin: 0;
}

.payment-methods {
  grid-column: span 2;
}

.payment-methods .pay-now-btn {
  width: 100%;
  margin-top: 1.5em;
}

.FB_CM_DISCOUNT {
  width: 100%;
  margin: 1em 0 !important;
  padding: 1.5em !important;
}

/* Queries */

@media screen and (max-width: 768px) {
  .n-default {
    width: 90%;
  }

  .cm-button {
    width: 100%;
  }

  .cm-group-select,
  .select-wrapper-group {
    grid-template-columns: 1fr;
  }

  .date-pickers {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  .w-container {
    padding: 0 15px;
  }

  .btn-holder {
    flex-direction: column-reverse;
    width: 100%;
  }

  .buy-process {
    margin: 0;
  }

  .btn-holder .cm-button.outlined {
    margin-bottom: 1rem;
  }

  .grid-m-c-1 {
    grid-template-columns: 1fr !important;
    margin-top: 0;
  }

  .p-buy-insurance-info-img {
    display: none;
  }

  .cm-checkbox-position-span-grid {
    grid-column: auto;
    justify-self: start;
  }

  .cm-review-group {
    grid-template-columns: 1fr;
  }

  .cm-heading-onboarding {
    font-size: 1.5em;
    line-height: 1;
  }

  .text-span-2 {
    line-height: 2;
  }
}

.p-buy-insurance-info-grid p {
  line-height: 1.3;
}

@media screen and (max-width: 992px) {
  .p-buy-insurance-info-grid p {
    line-height: 1.3;
    font-size: 16px;
    font-weight: 400;
  }
}

.carousel__dot {
  width: 15px;
  height: 15px;
  background: #fff;
  margin: 0 5px;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
}
.carousel__dot--selected {
  background-color: #d42f42;
  border: 1px solid #fff;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.button-container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  .button-container {
    flex-direction: column;
    padding: 1em 0;
  }

  .button-container button {
    margin: 0.5em 0 !important;
    width: 100%;
  }
}

.custom-social-card-button-container {
  padding: 0;
}

.custom-social-card-button-container button {
  margin: 0 !important
}

@media (max-width: 600px) {
  .custom-social-card-button-container {
    margin-top: 1em;
  }
}
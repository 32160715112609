.container {
  margin-top: 0;
}

.card {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, .33);
  padding: 2em;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  height: 100%
}

@media(max-width: 1023px) {
  .two-columns {
    grid-column: span 2;
  }
}

@media(max-width: 768px) {
  .container {
    margin-top: 1.4em;
  }
}